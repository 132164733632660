import React from 'react'
import Layout from '../components/layout'
import SearchInput from '../components/SearchInput'
import Seo from "../components/seo"

const PatentPriorArtSearching = () => {

	return (
		<Layout>
            <Seo title="Patent / Prior Art Searching" />
            
			<h1 className="title">Patent / Prior Art Searching</h1>

            <SearchInput />

            <table>
                <tbody>
                    {/* HEADING */}

                    <tr className="thead title">
                        <td colSpan="2">Patents</td>
                    </tr>

                    <tr>
                        <td>AusPat (patent search)</td>
                        <td><a target="_blank" rel="noreferrer" href="http://pericles.ipaustralia.gov.au/ols/auspat/">http://pericles.ipaustralia.gov.au/ols/auspat/</a></td>
                    </tr>

                    <tr>
                        <td>Patentscope</td>
                        <td><a target="_blank" rel="noreferrer" href="https://patentscope.wipo.int/search/en/search.jsf">https://patentscope.wipo.int/search/en/search.jsf</a></td>
                    </tr>

                    <tr>
                        <td>Espacenet</td>
                        <td><a target="_blank" rel="noreferrer" href="https://worldwide.espacenet.com/advancedSearch?locale=en_EP">https://worldwide.espacenet.com/advancedSearch?locale=en_EP</a></td>
                    </tr>

                    <tr>
                        <td>USPTO Global Dossier</td>
                        <td><a target="_blank" rel="noreferrer" href="https://globaldossier.uspto.gov/#/">https://globaldossier.uspto.gov/#/</a></td>
                    </tr>

                    <tr>
                        <td>Google Patents</td>
                        <td><a target="_blank" rel="noreferrer" href="https://patents.google.com/">https://patents.google.com/</a></td>
                    </tr>

                    <tr>
                        <td>Lens (patent and prior art search)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.lens.org/">https://www.lens.org/</a></td>
                    </tr>

                    <tr className="thead subtitle">
                        <td colSpan="2">Prior art searching</td>
                    </tr>

                    <tr>
                        <td>Google Scholar</td>
                        <td><a target="_blank" rel="noreferrer" href="https://scholar.google.com.au/">https://scholar.google.com.au/</a></td>
                    </tr>

                    <tr>
                        <td>IP.com prior art database</td>
                        <td><a target="_blank" rel="noreferrer" href="https://priorart.ip.com/index.html?_ga=2.143281405.934970319.1645661786-1685350961.1645661786">https://priorart.ip.com/index.html?_ga=2.143281405.934970319.1645661786-1685350961.1645661786</a></td>
                    </tr>
                </tbody>
            </table>
		</Layout>
	)
}

export default PatentPriorArtSearching